<template>
  <el-menu
    :default-active="activeRoute"
    class="h-screen el-menu-vertical-demo"
    :collapse="isCollapse"
    router
  >
    <div class="flex items-center justify-center w-full">
      <div class="w-[295px] h-[60px]">
        <img
          src="~/public/images/young-body-logo.png"
          class="object-cover w-full h-full"
          alt="logo"
        />
      </div>
    </div>

    <template v-for="item in menuItems" :key="item.key">
      <el-menu-item
        :index="item.path"
        :disabled="isMenuLocked && item.key !== 'profile'"
        @click="handleMenuClick(item)"
      >
        <el-icon>
          <component
            :is="item.icon"
            v-if="!isMenuLocked || item.key === 'profile'"
          />
          <Lock v-else />
        </el-icon>
        <template #title>{{ item.label }}</template>
      </el-menu-item>
    </template>
  </el-menu>
</template>

<script lang="ts" setup>
  import { ref, computed } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { ElMessage } from 'element-plus'
  import {
    User,
    School,
    Setting,
    Document,
    PieChart,
    Lock
  } from '@element-plus/icons-vue'
  import { useMyAuthStore } from '~/stores/auth.store'
  import type { MenuItem } from '~/types'
  import { UserStatus } from '~/enums'

  const route = useRoute()
  const router = useRouter()
  const authStore = useMyAuthStore()
  const isCollapse = ref(false)

  const activeRoute = computed(() => route.path)

  const isMenuLocked = computed(() => {
    return authStore.getProfile?.statusId === UserStatus.Rejected
  })

  const adminMenuItems: MenuItem[] = [
    {
      key: 'students',
      label: 'Học Viên',
      icon: User,
      path: '/admin/students'
    },
    {
      key: 'class',
      label: 'Lớp Học',
      icon: School,
      path: '/admin/class'
    },
    {
      key: 'settings',
      label: 'Cài đặt hệ thống',
      icon: Setting,
      path: '/admin/settings'
    }
  ]

  const StudentMenuItems: MenuItem[] = [
    {
      key: 'profile',
      label: 'Thông tin cá nhân',
      icon: Document,
      path: '/student/profile'
    },
    {
      key: 'reports',
      label: 'Báo cáo hàng tuần',
      icon: PieChart,
      path: '/student/reports'
    }
  ]

  const menuItems = computed(() => {
    const isAdmin = authStore.isAdmin
    const isEmployee = authStore.isEmployee

    if (isAdmin || isEmployee) return adminMenuItems
    return StudentMenuItems
  })

  const handleMenuClick = (item: MenuItem) => {
    if (isMenuLocked.value && item.key !== 'profile') {
      ElMessage({
        message:
          'Tài khoản của bạn phải được phê duyệt để sử dụng các tính năng còn lại của hệ thống',
        type: 'warning'
      })
      return
    }
    router.push(item.path)
  }

  // const handleOpen = (key: string, keyPath: string[]) => {
  //   console.log(key, keyPath)
  // }

  // const handleClose = (key: string, keyPath: string[]) => {
  //   console.log(key, keyPath)
  // }
</script>

<style>
  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 100%;
  }

  .el-menu-item {
    margin: 0 12px;
    border-radius: 8px;
    transition: all 0.3s ease;
    max-height: 56px; /* Default height */
  }

  .el-menu-item.is-active {
    background-color: var(--el-menu-hover-bg-color) !important;
    max-height: 40px; /* Active state height */
    transition: max-height 0.3s ease;
  }

  .el-menu-item:not(.is-active):hover {
    background-color: transparent !important;
    color: var(--el-menu-hover-text-color) !important;
  }

  .el-menu-item.is-disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
</style>
