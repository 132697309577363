<script lang="ts" setup>
  import { AppSideBar, AppHeader } from '@/components'
  import { onMounted, onUnmounted, ref, watch } from 'vue'
  import { Close, Menu } from '@element-plus/icons-vue'
  import { useRoute } from 'vue-router'

  const isMobileMenuOpen = ref(false)
  const isMobile = ref(false)
  const route = useRoute()

  const checkMobile = () => {
    isMobile.value = window.innerWidth < 768
  }

  // Check on mount and window resize
  onMounted(() => {
    checkMobile()
    window.addEventListener('resize', checkMobile)
  })

  onUnmounted(() => {
    window.removeEventListener('resize', checkMobile)
  })

  const toggleMobileMenu = () => {
    isMobileMenuOpen.value = !isMobileMenuOpen.value
  }

  const closeMobileMenu = () => {
    isMobileMenuOpen.value = false
  }

  // Watch for route changes to close mobile menu
  watch(
    () => route.path,
    () => {
      if (isMobileMenuOpen.value) {
        closeMobileMenu()
      }
    }
  )
</script>

<template>
  <div class="common-layout">
    <el-container>
      <!-- Mobile menu button -->
      <button
        v-if="isMobile"
        class="fixed z-50 top-4 left-4"
        @click="toggleMobileMenu"
      >
        <el-icon size="24">
          <Menu v-if="!isMobileMenuOpen" />
          <Close v-else />
        </el-icon>
      </button>

      <!-- Sidebar - overlay on mobile -->
      <el-aside
        :class="{
          'fixed inset-y-0 left-0 z-50': isMobile,
          'translate-x-0': isMobileMenuOpen,
          '-translate-x-full': !isMobileMenuOpen && isMobile
        }"
        :style="{ width: isMobile ? '250px' : '250px' }"
        class="transition-transform duration-300"
      >
        <div class="relative h-full">
          <AppSideBar />
          <button
            v-if="isMobile"
            class="absolute top-4 right-4"
            @click="closeMobileMenu"
          >
            <el-icon size="20">
              <Close />
            </el-icon>
          </button>
        </div>
      </el-aside>

      <!-- Backdrop -->
      <div
        v-if="isMobile && isMobileMenuOpen"
        class="fixed inset-0 z-30 bg-black bg-opacity-50"
        @click="closeMobileMenu"
      />

      <el-container class="flex flex-col items-start w-full h-screen">
        <el-header class="w-full h-full !px-0">
          <AppHeader />
        </el-header>
        <el-main class="w-full h-full !p-0 bg-lightGray">
          <slot />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<style scoped>
  .el-aside {
    @apply bg-white;
  }

  @media (min-width: 768px) {
    .el-aside {
      transform: none !important;
    }
  }
</style>
