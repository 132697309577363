<template>
  <div class="w-full h-full px-6 border-b header-container border-blueGray">
    <div class="flex items-center justify-between h-full">
      <div class="hidden lg:block breadcrumb-section min-w-fit">
        <el-breadcrumb :separator-icon="ArrowRight">
          <div class="flex items-center overflow-hidden">
            <TransitionGroup
              name="breadcrumb"
              tag="div"
              class="flex items-center gap-2"
              mode="out-in"
            >
              <el-breadcrumb-item
                :key="'home'"
                :to="{ path: homeRoute }"
                class="breadcrumb-item"
              >
                <div class="breadcrumb-content">
                  <el-icon class="breadcrumb-icon">
                    <House />
                  </el-icon>
                  <span class="breadcrumb-text">Trang chủ</span>
                </div>
              </el-breadcrumb-item>
              <el-breadcrumb-item :key="route.path" class="breadcrumb-item">
                <div class="breadcrumb-content">
                  <el-icon class="breadcrumb-icon">
                    <component :is="getBreadcrumbIcon" />
                  </el-icon>
                  <span class="breadcrumb-text">{{ breadcrumbText }}</span>
                </div>
              </el-breadcrumb-item>
            </TransitionGroup>
          </div>
        </el-breadcrumb>
      </div>

      <div class="ml-auto profile-section">
        <el-dropdown trigger="click">
          <div
            class="flex items-center cursor-pointer h-[56px] whitespace-nowrap overflow-hidden"
          >
            <el-avatar
              class="flex-shrink-0 mr-2"
              size="small"
              src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"
            />
            <span
              class="mr-1 text-sm font-medium truncate max-w-[100px] sm:max-w-[150px] md:max-w-[200px]"
              >{{ userProfile?.fullName }}</span
            >
            <el-icon class="flex-shrink-0 el-icon--right">
              <arrow-down />
            </el-icon>
          </div>

          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="handleLogout">
                <el-icon><switch-button /></el-icon>
                <span class="ml-2">Đăng xuất</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { computed, onMounted } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import {
    ArrowDown,
    ArrowRight,
    SwitchButton,
    House,
    User as UserIcon,
    Document,
    School,
    Setting,
    PieChart
  } from '@element-plus/icons-vue'
  import { token } from '~/data/local'
  import { useMyAuthStore } from '~/stores/auth.store'
  import { UserRole } from '~/enums'

  const route = useRoute()
  const router = useRouter()
  const authStore = useMyAuthStore()

  const userProfile = computed(() => authStore.getProfile)

  const homeRoute = computed(() => {
    return userProfile.value?.roleId === UserRole.Student
      ? '/student/profile'
      : '/admin/students'
  })

  const breadcrumbText = computed(() => {
    const pathMap: Record<string, string> = {
      '/admin/students': 'Học Viên',
      '/admin/class': 'Lớp Học',
      '/admin/settings': 'Cài đặt hệ thống',
      '/student/profile': 'Thông tin cá nhân',
      '/student/reports': 'Báo cáo hàng tuần',
      '/admin/class/detail': 'Thông tin lớp học',
      '/admin/students/detail': 'Thông tin học viên'
    }

    // Handle dynamic routes with params
    if (route.path.startsWith('/admin/students/detail/')) {
      return 'Thông tin học viên'
    }

    return pathMap[route.path] || route.name?.toString() || ''
  })

  const getBreadcrumbIcon = computed(() => {
    if (route.path === '/admin/students') {
      return UserIcon
    }
    if (route.path === '/admin/class') {
      return School
    }
    if (route.path === '/admin/settings') {
      return Setting
    }
    if (route.path === '/student/profile') {
      return Document
    }
    if (route.path === '/student/reports') {
      return PieChart
    }
    if (route.path === '/admin/class/detail') {
      return School
    }

    if (route.path.startsWith('/admin/students/detail/')) {
      return UserIcon
    }

    return House
  })

  const handleLogout = async () => {
    // Clear token from storage
    const tokenPref = token()
    await tokenPref.delete()

    // Clear auth store
    authStore.$reset()

    // Redirect to login page
    router.push('/login')
  }

  onMounted(() => {
    if (!userProfile.value) {
      authStore.getProfileHandler()
    }
  })
</script>

<style scoped lang="scss">
  .header-container {
    @apply bg-white;
  }

  :deep(.el-dropdown-menu__item) {
    @apply flex items-center;
  }

  .breadcrumb-item {
    @apply flex items-center;
  }

  .breadcrumb-content {
    @apply flex items-center gap-2 px-4 py-2 rounded-lg transition-all duration-300;

    &:hover {
      @apply bg-primary/5;
    }
  }

  .breadcrumb-icon {
    @apply text-lg text-secondary transition-transform duration-300;
  }

  .breadcrumb-text {
    @apply text-sm font-medium text-secondary transition-colors duration-300;
  }

  // Breadcrumb animations
  .breadcrumb-enter-active {
    transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);
    transition-delay: 0.1s;
  }

  .breadcrumb-leave-active {
    transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);
    position: absolute;
  }

  .breadcrumb-enter-from {
    opacity: 0;
    transform: translateX(-20px);
  }

  .breadcrumb-leave-to {
    opacity: 0;
    transform: translateX(20px);
  }

  .breadcrumb-move {
    transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  }

  :deep(.el-breadcrumb__item) {
    .el-breadcrumb__inner {
      @apply flex items-center relative;

      &.is-link {
        .breadcrumb-content {
          @apply hover:bg-primary/10;

          .breadcrumb-icon {
            @apply hover:scale-110 text-secondary;
          }

          .breadcrumb-text {
            @apply hover:text-primary;
          }
        }
      }
    }

    &:last-child {
      .el-breadcrumb__inner {
        .breadcrumb-content {
          @apply hover:bg-transparent;

          .breadcrumb-icon {
            @apply text-secondary;
          }

          .breadcrumb-text {
            @apply text-secondary;
          }
        }
      }
    }

    .el-breadcrumb__separator {
      @apply text-secondary;
    }
  }
</style>
